const _temp0 = require("url:./butter.png");
const _temp1 = require("url:./clear.png");
const _temp2 = require("url:./cloud.png");
const _temp3 = require("url:./cream.png");
const _temp4 = require("url:./crunch.png");
const _temp5 = require("url:./crystal.png");
const _temp6 = require("url:./dessert.png");
const _temp7 = require("url:./jungle.png");
const _temp8 = require("url:./ninja.png");
const _temp9 = require("url:./slime.png");
const _temp10 = require("url:./slimer.png");
module.exports = {
  "butter": _temp0,
  "clear": _temp1,
  "cloud": _temp2,
  "cream": _temp3,
  "crunch": _temp4,
  "crystal": _temp5,
  "dessert": _temp6,
  "jungle": _temp7,
  "ninja": _temp8,
  "slime": _temp9,
  "slimer": _temp10
}