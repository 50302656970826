const _temp0 = require("url:./slimer-mix.png");
const _temp1 = require("url:./SR002.png");
const _temp2 = require("url:./SR003.png");
const _temp3 = require("url:./SR004.png");
const _temp4 = require("url:./SR149.png");
const _temp5 = require("url:./SR150.png");
const _temp6 = require("url:./SR151.png");
const _temp7 = require("url:./SR152.png");
const _temp8 = require("url:./SSS30-145.png");
const _temp9 = require("url:./SSS30-146.png");
const _temp10 = require("url:./SSS30-150.png");
const _temp11 = require("url:./SSS30-153.png");
module.exports = {
  "slimer-mix": _temp0,
  "SR002": _temp1,
  "SR003": _temp2,
  "SR004": _temp3,
  "SR149": _temp4,
  "SR150": _temp5,
  "SR151": _temp6,
  "SR152": _temp7,
  "SSS30-145": _temp8,
  "SSS30-146": _temp9,
  "SSS30-150": _temp10,
  "SSS30-153": _temp11
}