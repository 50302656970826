const _temp0 = require("url:./butter.mp4");
const _temp1 = require("url:./clear.mp4");
const _temp2 = require("url:./cloud.mp4");
const _temp3 = require("url:./cream.mp4");
const _temp4 = require("url:./crunch.mp4");
const _temp5 = require("url:./crystal.mp4");
const _temp6 = require("url:./dessert.mp4");
const _temp7 = require("url:./jungle.mp4");
const _temp8 = require("url:./ninja.mp4");
const _temp9 = require("url:./slime-new.mp4");
const _temp10 = require("url:./slime.mp4");
module.exports = {
  "butter": _temp0,
  "clear": _temp1,
  "cloud": _temp2,
  "cream": _temp3,
  "crunch": _temp4,
  "crystal": _temp5,
  "dessert": _temp6,
  "jungle": _temp7,
  "ninja": _temp8,
  "slime-new": _temp9,
  "slime": _temp10
}