import products_img from 'url:../img/shop/*.jpg'
import categories from '../category.json'
import SimpleBar from 'simplebar';
import wb_logo from 'url:../img/wb-logo.png'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import ozon_logo from 'url:../img/ozon-logo.png'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import detmir_logo from 'url:../img/detmir-logo.png'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import brands_logos from 'url:../img/shop-brands/*.png'
function initShop(){
    
    $(document).on('click', '.buy-btn, .close-links', function(){
        $(this).closest('.buttons-overlay').toggleClass('links-opened')
    })
    const simplebar = new SimpleBar(document.querySelector('.simple-bar-container'));
    $('.shop-modal .close-modal, .shop-modal__overlay').on('click', function(){
        $('body').toggleClass('shop-modal-opened')
    })
    $(document).on('click', '.brands .brand, .show-btn, .prev-cat, .next-cat', showCategory)

    function showCategory(e) {
        
        simplebar.recalculate();
        const data = $(this).data('category');
        const cat_arr = data.split('>');
        const is_switch = $(this).is('.prev-cat, .next-cat') || false;
        const is_slimer = cat_arr.length > 1 ? true : false;

        if (is_slimer) {
            let next_cat = $(`.products [data-category="${data}"]`).not('.slick-cloned').first().closest('.product').next().find('.show-btn').data('category') 
            let prev_cat = $(`.products [data-category="${data}"]`).not('.slick-cloned').first().closest('.product').prev().find('.show-btn').data('category') || $('.products .product').not('.slick-cloned').last().find('.show-btn').data('category')
            $('.next-cat').data('category', next_cat)
            $('.prev-cat').data('category', prev_cat)
            console.log(prev_cat, next_cat)
        }else {
            let next_cat = $(`.brand[data-category="${data}"]`).not('.slick-cloned').first().next().data('category')
            let prev_cat = $(`.brand[data-category="${data}"]`).not('.slick-cloned').first().prev().data('category') || $('.brand').not('.slick-cloned').last().data('category')
            $('.next-cat').data('category', next_cat)
            $('.prev-cat').data('category', prev_cat)
            console.log(prev_cat, next_cat)
        }
        if (is_switch) {
            $('.shop-modal__product').one('transitionend', function (e) {
                e.stopPropagation()
                renderProducts();
                setTimeout(function () {
                    $('.shop-modal__product').one('transitionend', function(e){
                        console.log(e.target)
                    })
                    $('.shop-modal__product').addClass('shop-modal__product--animate');
                    $('.shop-modal__logo').addClass('shop-modal__logo--animate');
                    $('.shop-modal__description').addClass('shop-modal__description--animate');
                    // console.log('addd')
                })
            })
            $('.shop-modal__product').css({'transition-delay': '0s'})
            $('.shop-modal__product').removeClass('shop-modal__product--animate')

            $('.shop-modal__logo').removeClass('shop-modal__logo--animate');
            $('.shop-modal__description').removeClass('shop-modal__description--animate');
        }else {
            renderProducts();
            $('body').toggleClass('shop-modal-opened');
            $('.shop-modal').on('transitionend', function (e) {
                if ($(e.target).hasClass('shop-modal')) {
                    $('.shop-modal__product').one('transitionend', function(e){
                        console.log(e.target)
                    })
                    $('.shop-modal__product').addClass('shop-modal__product--animate');
                    $('.shop-modal__logo').addClass('shop-modal__logo--animate');
                    $('.shop-modal__description').addClass('shop-modal__description--animate');
                }
            })
        }

        function renderProducts(){
            const category = categories.find(x => x.name == cat_arr[0])
            let subcategories = false
            let logo = brands_logos[category.logo]
            if (cat_arr.length > 1 ) {
                subcategories = cat_arr[1].split(';');
            }
            $('.shop-modal .shop-modal__logo').html(`<img src="${logo}" alt="">`)
            $('.shop-modal .shop-modal__description').html(category.description)
            $('.shop-modal__products').children().remove()

            var products = []
            if (subcategories) {
                products = subcategories.reduce((acc, subcategory) => {
                    let res =  category.products.filter(el=>el.subcategory == subcategory)
                    return acc.concat(res)
                }, [])
            }else{
                products = category.products
            }

            let delay = 0;
            products.forEach(function(product){
                if (!product.wb && !product.ozon && !product.detmir || !products_img[product.article]) {
                    return
                }
                $('.shop-modal__products').append(createProduct(product.name, products_img[product.article], product.wb, product.ozon, product.detmir, product.article, delay))
                delay += 0.08;
                delay = parseFloat(delay.toFixed(3))
            })
        }
    }
    const exceptions_sku = ['S130-56', 'S130-59', 'S130-60', 'S130-61', 'S130-62', 'S130-63', 'SR149', 'SR150', 'SR151', 'SR152']
    function createProduct(title, img, wb_link, ozon_link, detmir_link, article, delay){
        return `<div class="shop-modal__product" style="transition-delay: ${delay}s">
                                <div class="shop-modal__product__img"><img src="${img}" alt=""></div>
                                <div class="shop-modal__product__title">${title}</div>
                                <div class="buttons-overlay">
                                    ${exceptions_sku.indexOf(article) == -1 ? '<button class="buy-btn btn btn--blue">купить</button>' : '<div class="soon">Скоро <br>в продаже</div>'}
            
                                    <div class="markets-links">
                                        ${wb_link == '' ? '' : `<a href="${wb_link}" target="_blank" class="btn btn--primary market-link"><img src="${wb_logo}" alt=""></a>`}
                                        ${ozon_link == '' ? '' : `<a href="${ozon_link}" target="_blank" class="btn btn--primary market-link"><img src="${ozon_logo}" alt=""></a>`}
                                        ${detmir_link == '' ? '' : `<a href="${detmir_link}" target="_blank" class="btn btn--primary market-link"><img src="${detmir_logo}" alt=""></a>`}
                                        <button class="btn btn--blue close-links">закрыть</button>
                                    </div>
                                </div>
                            </div>`;
                        
    }
}
 export {initShop}
