const _temp0 = require("url:./1.png");
const _temp1 = require("url:./2.png");
const _temp2 = require("url:./3.png");
const _temp3 = require("url:./4.png");
const _temp4 = require("url:./5.png");
const _temp5 = require("url:./6.png");
const _temp6 = require("url:./7.png");
const _temp7 = require("url:./8.png");
const _temp8 = require("url:./9.png");
const _temp9 = require("url:./10.png");
const _temp10 = require("url:./11.png");
const _temp11 = require("url:./12.png");
module.exports = {
  "1": _temp0,
  "2": _temp1,
  "3": _temp2,
  "4": _temp3,
  "5": _temp4,
  "6": _temp5,
  "7": _temp6,
  "8": _temp7,
  "9": _temp8,
  "10": _temp9,
  "11": _temp10,
  "12": _temp11
}