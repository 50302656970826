const _temp0 = require("url:./AQ001.jpg");
const _temp1 = require("url:./AQ002.jpg");
const _temp2 = require("url:./AQ003.jpg");
const _temp3 = require("url:./S130-1.jpg");
const _temp4 = require("url:./S130-10.jpg");
const _temp5 = require("url:./S130-11.jpg");
const _temp6 = require("url:./S130-12.jpg");
const _temp7 = require("url:./S130-13.jpg");
const _temp8 = require("url:./S130-15.jpg");
const _temp9 = require("url:./S130-16.jpg");
const _temp10 = require("url:./S130-17.jpg");
const _temp11 = require("url:./S130-18.jpg");
const _temp12 = require("url:./S130-19.jpg");
const _temp13 = require("url:./S130-2.jpg");
const _temp14 = require("url:./S130-20.jpg");
const _temp15 = require("url:./S130-23.jpg");
const _temp16 = require("url:./S130-24.jpg");
const _temp17 = require("url:./S130-25.jpg");
const _temp18 = require("url:./S130-26.jpg");
const _temp19 = require("url:./S130-27.jpg");
const _temp20 = require("url:./S130-28.jpg");
const _temp21 = require("url:./S130-29.jpg");
const _temp22 = require("url:./S130-3.jpg");
const _temp23 = require("url:./S130-31.jpg");
const _temp24 = require("url:./S130-32.jpg");
const _temp25 = require("url:./S130-34.jpg");
const _temp26 = require("url:./S130-37.jpg");
const _temp27 = require("url:./S130-39.jpg");
const _temp28 = require("url:./S130-4.jpg");
const _temp29 = require("url:./S130-40.jpg");
const _temp30 = require("url:./S130-42.jpg");
const _temp31 = require("url:./S130-43.jpg");
const _temp32 = require("url:./S130-44.jpg");
const _temp33 = require("url:./S130-45.jpg");
const _temp34 = require("url:./S130-5.jpg");
const _temp35 = require("url:./S130-56.jpg");
const _temp36 = require("url:./S130-59.jpg");
const _temp37 = require("url:./S130-6.jpg");
const _temp38 = require("url:./S130-60.jpg");
const _temp39 = require("url:./S130-61.jpg");
const _temp40 = require("url:./S130-62.jpg");
const _temp41 = require("url:./S130-63.jpg");
const _temp42 = require("url:./S130-7.jpg");
const _temp43 = require("url:./S130-8.jpg");
const _temp44 = require("url:./S130-9.jpg");
const _temp45 = require("url:./S300-1.jpg");
const _temp46 = require("url:./S300-10.jpg");
const _temp47 = require("url:./S300-12.jpg");
const _temp48 = require("url:./S300-13.jpg");
const _temp49 = require("url:./S300-14.jpg");
const _temp50 = require("url:./S300-15.jpg");
const _temp51 = require("url:./S300-16.jpg");
const _temp52 = require("url:./S300-17.jpg");
const _temp53 = require("url:./S300-18.jpg");
const _temp54 = require("url:./S300-19.jpg");
const _temp55 = require("url:./S300-2.jpg");
const _temp56 = require("url:./S300-20.jpg");
const _temp57 = require("url:./S300-21.jpg");
const _temp58 = require("url:./S300-22.jpg");
const _temp59 = require("url:./S300-23.jpg");
const _temp60 = require("url:./S300-24.jpg");
const _temp61 = require("url:./S300-25.jpg");
const _temp62 = require("url:./S300-26.jpg");
const _temp63 = require("url:./S300-27.jpg");
const _temp64 = require("url:./S300-28.jpg");
const _temp65 = require("url:./S300-29.jpg");
const _temp66 = require("url:./S300-3.jpg");
const _temp67 = require("url:./S300-30.jpg");
const _temp68 = require("url:./S300-31.jpg");
const _temp69 = require("url:./S300-32.jpg");
const _temp70 = require("url:./S300-33.jpg");
const _temp71 = require("url:./S300-35.jpg");
const _temp72 = require("url:./S300-36.jpg");
const _temp73 = require("url:./S300-37.jpg");
const _temp74 = require("url:./S300-38.jpg");
const _temp75 = require("url:./S300-39.jpg");
const _temp76 = require("url:./S300-4.jpg");
const _temp77 = require("url:./S300-5.jpg");
const _temp78 = require("url:./S300-6.jpg");
const _temp79 = require("url:./S300-7.jpg");
const _temp80 = require("url:./S300-8.jpg");
const _temp81 = require("url:./S300-9.jpg");
const _temp82 = require("url:./S500-1.jpg");
const _temp83 = require("url:./S500-10.jpg");
const _temp84 = require("url:./S500-10188.jpg");
const _temp85 = require("url:./S500-2.jpg");
const _temp86 = require("url:./S500-20181.jpg");
const _temp87 = require("url:./S500-20182.jpg");
const _temp88 = require("url:./S500-20183.jpg");
const _temp89 = require("url:./S500-20188.jpg");
const _temp90 = require("url:./S500-3.jpg");
const _temp91 = require("url:./S500-4.jpg");
const _temp92 = require("url:./S500-5.jpg");
const _temp93 = require("url:./S500-6.jpg");
const _temp94 = require("url:./S500-7.jpg");
const _temp95 = require("url:./S500-8.jpg");
const _temp96 = require("url:./S500-9.jpg");
const _temp97 = require("url:./SF02-B.jpg");
const _temp98 = require("url:./SF02-G.jpg");
const _temp99 = require("url:./SF02-I.jpg");
const _temp100 = require("url:./SF02-J.jpg");
const _temp101 = require("url:./SF02-K.jpg");
const _temp102 = require("url:./SF02-M.jpg");
const _temp103 = require("url:./SF02-S.jpg");
const _temp104 = require("url:./SF02-SB.jpg");
const _temp105 = require("url:./SF05-B.jpg");
const _temp106 = require("url:./SF05-I.jpg");
const _temp107 = require("url:./SF05-J.jpg");
const _temp108 = require("url:./SF05-S.jpg");
const _temp109 = require("url:./SF05-X.jpg");
const _temp110 = require("url:./SM200-01.jpg");
const _temp111 = require("url:./SM200-02.jpg");
const _temp112 = require("url:./SM200-03.jpg");
const _temp113 = require("url:./SM200-04.jpg");
const _temp114 = require("url:./SR001.jpg");
const _temp115 = require("url:./SR002.jpg");
const _temp116 = require("url:./SR003.jpg");
const _temp117 = require("url:./SR004.jpg");
const _temp118 = require("url:./SR005.jpg");
const _temp119 = require("url:./SR100.jpg");
const _temp120 = require("url:./SR101.jpg");
const _temp121 = require("url:./SR102.jpg");
const _temp122 = require("url:./SR104.jpg");
const _temp123 = require("url:./SR105.jpg");
const _temp124 = require("url:./SR106.jpg");
const _temp125 = require("url:./SR109.jpg");
const _temp126 = require("url:./SR110.jpg");
const _temp127 = require("url:./SR111.jpg");
const _temp128 = require("url:./SR112.jpg");
const _temp129 = require("url:./SR113.jpg");
const _temp130 = require("url:./SR114.jpg");
const _temp131 = require("url:./SR115.jpg");
const _temp132 = require("url:./SR116.jpg");
const _temp133 = require("url:./SR117.jpg");
const _temp134 = require("url:./SR118.jpg");
const _temp135 = require("url:./SR119.jpg");
const _temp136 = require("url:./SR120.jpg");
const _temp137 = require("url:./SR121.jpg");
const _temp138 = require("url:./SR122.jpg");
const _temp139 = require("url:./SR123.jpg");
const _temp140 = require("url:./SR124.jpg");
const _temp141 = require("url:./SR125.jpg");
const _temp142 = require("url:./SR126.jpg");
const _temp143 = require("url:./SR127.jpg");
const _temp144 = require("url:./SR128.jpg");
const _temp145 = require("url:./SR129.jpg");
const _temp146 = require("url:./SR130.jpg");
const _temp147 = require("url:./SR131.jpg");
const _temp148 = require("url:./SR132.jpg");
const _temp149 = require("url:./SR133.jpg");
const _temp150 = require("url:./SR134.jpg");
const _temp151 = require("url:./SR135.jpg");
const _temp152 = require("url:./SR136.jpg");
const _temp153 = require("url:./SR137.jpg");
const _temp154 = require("url:./SR138.jpg");
const _temp155 = require("url:./SR139.jpg");
const _temp156 = require("url:./SR140.jpg");
const _temp157 = require("url:./SR141.jpg");
const _temp158 = require("url:./SR142.jpg");
const _temp159 = require("url:./SR149.jpg");
const _temp160 = require("url:./SR150.jpg");
const _temp161 = require("url:./SR151.jpg");
const _temp162 = require("url:./SR152.jpg");
const _temp163 = require("url:./SS100-1.jpg");
const _temp164 = require("url:./SS100-2.jpg");
const _temp165 = require("url:./SS100-30181.jpg");
const _temp166 = require("url:./SS100-4.jpg");
const _temp167 = require("url:./SS100-5.jpg");
const _temp168 = require("url:./SS100-6.jpg");
const _temp169 = require("url:./SS300-1.jpg");
const _temp170 = require("url:./SS300-2.jpg");
const _temp171 = require("url:./SS300-5.jpg");
const _temp172 = require("url:./SS500-30181.jpg");
const _temp173 = require("url:./SS500-30182.jpg");
const _temp174 = require("url:./SS500-30183.jpg");
const _temp175 = require("url:./SS500-30184.jpg");
const _temp176 = require("url:./SS500-30185.jpg");
const _temp177 = require("url:./SS500-30186.jpg");
const _temp178 = require("url:./SSS-28.jpg");
const _temp179 = require("url:./SSS-29.jpg");
const _temp180 = require("url:./SSS09-01.jpg");
const _temp181 = require("url:./SSS09-01mix.jpg");
const _temp182 = require("url:./SSS1-15.jpg");
const _temp183 = require("url:./SSS10-03.jpg");
const _temp184 = require("url:./SSS10-07.jpg");
const _temp185 = require("url:./SSS15-001.jpg");
const _temp186 = require("url:./SSS15-01.jpg");
const _temp187 = require("url:./SSS15-02.jpg");
const _temp188 = require("url:./SSS15-03.jpg");
const _temp189 = require("url:./SSS2-01.jpg");
const _temp190 = require("url:./SSS2-05.jpg");
const _temp191 = require("url:./SSS2-09.jpg");
const _temp192 = require("url:./SSS2-10.jpg");
const _temp193 = require("url:./SSS2-11.jpg");
const _temp194 = require("url:./SSS2-110.jpg");
const _temp195 = require("url:./SSS2-12.jpg");
const _temp196 = require("url:./SSS2-120.jpg");
const _temp197 = require("url:./SSS2-13.jpg");
const _temp198 = require("url:./SSS2-130.jpg");
const _temp199 = require("url:./SSS2-14.jpg");
const _temp200 = require("url:./SSS2-140.jpg");
const _temp201 = require("url:./SSS20-04.jpg");
const _temp202 = require("url:./SSS20-08.jpg");
const _temp203 = require("url:./SSS30-01.jpg");
const _temp204 = require("url:./SSS30-02.jpg");
const _temp205 = require("url:./SSS30-03.jpg");
const _temp206 = require("url:./SSS30-04.jpg");
const _temp207 = require("url:./SSS30-05.jpg");
const _temp208 = require("url:./SSS30-06.jpg");
const _temp209 = require("url:./SSS30-07.jpg");
const _temp210 = require("url:./SSS30-08.jpg");
const _temp211 = require("url:./SSS30-09.jpg");
const _temp212 = require("url:./SSS30-10.jpg");
const _temp213 = require("url:./SSS30-102.jpg");
const _temp214 = require("url:./SSS30-103.jpg");
const _temp215 = require("url:./SSS30-104.jpg");
const _temp216 = require("url:./SSS30-105.jpg");
const _temp217 = require("url:./SSS30-106.jpg");
const _temp218 = require("url:./SSS30-107.jpg");
const _temp219 = require("url:./SSS30-108.jpg");
const _temp220 = require("url:./SSS30-109.jpg");
const _temp221 = require("url:./SSS30-11.jpg");
const _temp222 = require("url:./SSS30-110.jpg");
const _temp223 = require("url:./SSS30-111.jpg");
const _temp224 = require("url:./SSS30-112.jpg");
const _temp225 = require("url:./SSS30-113.jpg");
const _temp226 = require("url:./SSS30-114.jpg");
const _temp227 = require("url:./SSS30-115.jpg");
const _temp228 = require("url:./SSS30-116.jpg");
const _temp229 = require("url:./SSS30-117.jpg");
const _temp230 = require("url:./SSS30-118.jpg");
const _temp231 = require("url:./SSS30-119.jpg");
const _temp232 = require("url:./SSS30-12.jpg");
const _temp233 = require("url:./SSS30-120.jpg");
const _temp234 = require("url:./SSS30-121.jpg");
const _temp235 = require("url:./SSS30-122.jpg");
const _temp236 = require("url:./SSS30-123.jpg");
const _temp237 = require("url:./SSS30-124.jpg");
const _temp238 = require("url:./SSS30-125.jpg");
const _temp239 = require("url:./SSS30-126.jpg");
const _temp240 = require("url:./SSS30-127.jpg");
const _temp241 = require("url:./SSS30-128.jpg");
const _temp242 = require("url:./SSS30-129.jpg");
const _temp243 = require("url:./SSS30-13.jpg");
const _temp244 = require("url:./SSS30-130.jpg");
const _temp245 = require("url:./SSS30-131.jpg");
const _temp246 = require("url:./SSS30-133.jpg");
const _temp247 = require("url:./SSS30-134.jpg");
const _temp248 = require("url:./SSS30-135.jpg");
const _temp249 = require("url:./SSS30-136.jpg");
const _temp250 = require("url:./SSS30-137.jpg");
const _temp251 = require("url:./SSS30-138.jpg");
const _temp252 = require("url:./SSS30-139.jpg");
const _temp253 = require("url:./SSS30-14.jpg");
const _temp254 = require("url:./SSS30-140.jpg");
const _temp255 = require("url:./SSS30-141.jpg");
const _temp256 = require("url:./SSS30-142.jpg");
const _temp257 = require("url:./SSS30-143.jpg");
const _temp258 = require("url:./SSS30-144.jpg");
const _temp259 = require("url:./SSS30-145.jpg");
const _temp260 = require("url:./SSS30-146.jpg");
const _temp261 = require("url:./SSS30-147.jpg");
const _temp262 = require("url:./SSS30-148.jpg");
const _temp263 = require("url:./SSS30-149.jpg");
const _temp264 = require("url:./SSS30-15.jpg");
const _temp265 = require("url:./SSS30-150.jpg");
const _temp266 = require("url:./SSS30-151.jpg");
const _temp267 = require("url:./SSS30-152.jpg");
const _temp268 = require("url:./SSS30-153.jpg");
const _temp269 = require("url:./SSS30-154.jpg");
const _temp270 = require("url:./SSS30-155.jpg");
const _temp271 = require("url:./SSS30-16.jpg");
const _temp272 = require("url:./SSS30-17.jpg");
const _temp273 = require("url:./SSS30-18.jpg");
const _temp274 = require("url:./SSS30-19.jpg");
const _temp275 = require("url:./SSS30-20.jpg");
const _temp276 = require("url:./SSS30-21.jpg");
const _temp277 = require("url:./SSS30-22.jpg");
const _temp278 = require("url:./SSS30-23.jpg");
const _temp279 = require("url:./SSS30-24.jpg");
const _temp280 = require("url:./SSS30-25.jpg");
const _temp281 = require("url:./SSS30-26.jpg");
const _temp282 = require("url:./SSS30-27.jpg");
const _temp283 = require("url:./SSS30-28.jpg");
const _temp284 = require("url:./SSS30-29.jpg");
const _temp285 = require("url:./SSS30-30.jpg");
const _temp286 = require("url:./SSS30-31.jpg");
const _temp287 = require("url:./SSS30-32.jpg");
const _temp288 = require("url:./SSS30-33.jpg");
const _temp289 = require("url:./SSS30-33A.jpg");
const _temp290 = require("url:./SSS30-34.jpg");
const _temp291 = require("url:./SSS30-36.jpg");
const _temp292 = require("url:./SSS30-38.jpg");
const _temp293 = require("url:./SSS30-40.jpg");
const _temp294 = require("url:./SSS30-41.jpg");
const _temp295 = require("url:./SSS30-42.jpg");
const _temp296 = require("url:./SSS30-43.jpg");
const _temp297 = require("url:./SSS30-44.jpg");
const _temp298 = require("url:./SSS30-45.jpg");
const _temp299 = require("url:./SSS30-46.jpg");
const _temp300 = require("url:./SSS30-47.jpg");
const _temp301 = require("url:./SSS30-48.jpg");
const _temp302 = require("url:./SSS30-49.jpg");
const _temp303 = require("url:./SSS30-50.jpg");
const _temp304 = require("url:./SSS30-50M.jpg");
const _temp305 = require("url:./SSS30-51.jpg");
const _temp306 = require("url:./SSS30-52.jpg");
const _temp307 = require("url:./SSS30-53.jpg");
const _temp308 = require("url:./SSS30-54.jpg");
const _temp309 = require("url:./SSS30-55.jpg");
const _temp310 = require("url:./SSS30-56.jpg");
const _temp311 = require("url:./SSS30-57.jpg");
const _temp312 = require("url:./SSS30-61.jpg");
const _temp313 = require("url:./SSS30-80.jpg");
const _temp314 = require("url:./SSS30-81.jpg");
const _temp315 = require("url:./SSS30-82.jpg");
const _temp316 = require("url:./SSS30-83.jpg");
const _temp317 = require("url:./SSS30-87.jpg");
const _temp318 = require("url:./SSS30-87SL.jpg");
const _temp319 = require("url:./SSS30-88.jpg");
const _temp320 = require("url:./SSS30-90.jpg");
const _temp321 = require("url:./SSS30-91.jpg");
const _temp322 = require("url:./SSS30-92.jpg");
const _temp323 = require("url:./SSS30-93.jpg");
const _temp324 = require("url:./SSS30-94.jpg");
const _temp325 = require("url:./SSS30-95.jpg");
const _temp326 = require("url:./SSS30-96.jpg");
const _temp327 = require("url:./SSS30-97.jpg");
const _temp328 = require("url:./SSS31-39F.jpg");
const _temp329 = require("url:./SSS31-39G.jpg");
const _temp330 = require("url:./SSS31-39W.jpg");
const _temp331 = require("url:./SSS31-39Y.jpg");
const _temp332 = require("url:./SSS31-39Р.jpg");
const _temp333 = require("url:./SSS5-02.jpg");
const _temp334 = require("url:./SSS5-06.jpg");
module.exports = {
  "AQ001": _temp0,
  "AQ002": _temp1,
  "AQ003": _temp2,
  "S130-1": _temp3,
  "S130-10": _temp4,
  "S130-11": _temp5,
  "S130-12": _temp6,
  "S130-13": _temp7,
  "S130-15": _temp8,
  "S130-16": _temp9,
  "S130-17": _temp10,
  "S130-18": _temp11,
  "S130-19": _temp12,
  "S130-2": _temp13,
  "S130-20": _temp14,
  "S130-23": _temp15,
  "S130-24": _temp16,
  "S130-25": _temp17,
  "S130-26": _temp18,
  "S130-27": _temp19,
  "S130-28": _temp20,
  "S130-29": _temp21,
  "S130-3": _temp22,
  "S130-31": _temp23,
  "S130-32": _temp24,
  "S130-34": _temp25,
  "S130-37": _temp26,
  "S130-39": _temp27,
  "S130-4": _temp28,
  "S130-40": _temp29,
  "S130-42": _temp30,
  "S130-43": _temp31,
  "S130-44": _temp32,
  "S130-45": _temp33,
  "S130-5": _temp34,
  "S130-56": _temp35,
  "S130-59": _temp36,
  "S130-6": _temp37,
  "S130-60": _temp38,
  "S130-61": _temp39,
  "S130-62": _temp40,
  "S130-63": _temp41,
  "S130-7": _temp42,
  "S130-8": _temp43,
  "S130-9": _temp44,
  "S300-1": _temp45,
  "S300-10": _temp46,
  "S300-12": _temp47,
  "S300-13": _temp48,
  "S300-14": _temp49,
  "S300-15": _temp50,
  "S300-16": _temp51,
  "S300-17": _temp52,
  "S300-18": _temp53,
  "S300-19": _temp54,
  "S300-2": _temp55,
  "S300-20": _temp56,
  "S300-21": _temp57,
  "S300-22": _temp58,
  "S300-23": _temp59,
  "S300-24": _temp60,
  "S300-25": _temp61,
  "S300-26": _temp62,
  "S300-27": _temp63,
  "S300-28": _temp64,
  "S300-29": _temp65,
  "S300-3": _temp66,
  "S300-30": _temp67,
  "S300-31": _temp68,
  "S300-32": _temp69,
  "S300-33": _temp70,
  "S300-35": _temp71,
  "S300-36": _temp72,
  "S300-37": _temp73,
  "S300-38": _temp74,
  "S300-39": _temp75,
  "S300-4": _temp76,
  "S300-5": _temp77,
  "S300-6": _temp78,
  "S300-7": _temp79,
  "S300-8": _temp80,
  "S300-9": _temp81,
  "S500-1": _temp82,
  "S500-10": _temp83,
  "S500-10188": _temp84,
  "S500-2": _temp85,
  "S500-20181": _temp86,
  "S500-20182": _temp87,
  "S500-20183": _temp88,
  "S500-20188": _temp89,
  "S500-3": _temp90,
  "S500-4": _temp91,
  "S500-5": _temp92,
  "S500-6": _temp93,
  "S500-7": _temp94,
  "S500-8": _temp95,
  "S500-9": _temp96,
  "SF02-B": _temp97,
  "SF02-G": _temp98,
  "SF02-I": _temp99,
  "SF02-J": _temp100,
  "SF02-K": _temp101,
  "SF02-M": _temp102,
  "SF02-S": _temp103,
  "SF02-SB": _temp104,
  "SF05-B": _temp105,
  "SF05-I": _temp106,
  "SF05-J": _temp107,
  "SF05-S": _temp108,
  "SF05-X": _temp109,
  "SM200-01": _temp110,
  "SM200-02": _temp111,
  "SM200-03": _temp112,
  "SM200-04": _temp113,
  "SR001": _temp114,
  "SR002": _temp115,
  "SR003": _temp116,
  "SR004": _temp117,
  "SR005": _temp118,
  "SR100": _temp119,
  "SR101": _temp120,
  "SR102": _temp121,
  "SR104": _temp122,
  "SR105": _temp123,
  "SR106": _temp124,
  "SR109": _temp125,
  "SR110": _temp126,
  "SR111": _temp127,
  "SR112": _temp128,
  "SR113": _temp129,
  "SR114": _temp130,
  "SR115": _temp131,
  "SR116": _temp132,
  "SR117": _temp133,
  "SR118": _temp134,
  "SR119": _temp135,
  "SR120": _temp136,
  "SR121": _temp137,
  "SR122": _temp138,
  "SR123": _temp139,
  "SR124": _temp140,
  "SR125": _temp141,
  "SR126": _temp142,
  "SR127": _temp143,
  "SR128": _temp144,
  "SR129": _temp145,
  "SR130": _temp146,
  "SR131": _temp147,
  "SR132": _temp148,
  "SR133": _temp149,
  "SR134": _temp150,
  "SR135": _temp151,
  "SR136": _temp152,
  "SR137": _temp153,
  "SR138": _temp154,
  "SR139": _temp155,
  "SR140": _temp156,
  "SR141": _temp157,
  "SR142": _temp158,
  "SR149": _temp159,
  "SR150": _temp160,
  "SR151": _temp161,
  "SR152": _temp162,
  "SS100-1": _temp163,
  "SS100-2": _temp164,
  "SS100-30181": _temp165,
  "SS100-4": _temp166,
  "SS100-5": _temp167,
  "SS100-6": _temp168,
  "SS300-1": _temp169,
  "SS300-2": _temp170,
  "SS300-5": _temp171,
  "SS500-30181": _temp172,
  "SS500-30182": _temp173,
  "SS500-30183": _temp174,
  "SS500-30184": _temp175,
  "SS500-30185": _temp176,
  "SS500-30186": _temp177,
  "SSS-28": _temp178,
  "SSS-29": _temp179,
  "SSS09-01": _temp180,
  "SSS09-01mix": _temp181,
  "SSS1-15": _temp182,
  "SSS10-03": _temp183,
  "SSS10-07": _temp184,
  "SSS15-001": _temp185,
  "SSS15-01": _temp186,
  "SSS15-02": _temp187,
  "SSS15-03": _temp188,
  "SSS2-01": _temp189,
  "SSS2-05": _temp190,
  "SSS2-09": _temp191,
  "SSS2-10": _temp192,
  "SSS2-11": _temp193,
  "SSS2-110": _temp194,
  "SSS2-12": _temp195,
  "SSS2-120": _temp196,
  "SSS2-13": _temp197,
  "SSS2-130": _temp198,
  "SSS2-14": _temp199,
  "SSS2-140": _temp200,
  "SSS20-04": _temp201,
  "SSS20-08": _temp202,
  "SSS30-01": _temp203,
  "SSS30-02": _temp204,
  "SSS30-03": _temp205,
  "SSS30-04": _temp206,
  "SSS30-05": _temp207,
  "SSS30-06": _temp208,
  "SSS30-07": _temp209,
  "SSS30-08": _temp210,
  "SSS30-09": _temp211,
  "SSS30-10": _temp212,
  "SSS30-102": _temp213,
  "SSS30-103": _temp214,
  "SSS30-104": _temp215,
  "SSS30-105": _temp216,
  "SSS30-106": _temp217,
  "SSS30-107": _temp218,
  "SSS30-108": _temp219,
  "SSS30-109": _temp220,
  "SSS30-11": _temp221,
  "SSS30-110": _temp222,
  "SSS30-111": _temp223,
  "SSS30-112": _temp224,
  "SSS30-113": _temp225,
  "SSS30-114": _temp226,
  "SSS30-115": _temp227,
  "SSS30-116": _temp228,
  "SSS30-117": _temp229,
  "SSS30-118": _temp230,
  "SSS30-119": _temp231,
  "SSS30-12": _temp232,
  "SSS30-120": _temp233,
  "SSS30-121": _temp234,
  "SSS30-122": _temp235,
  "SSS30-123": _temp236,
  "SSS30-124": _temp237,
  "SSS30-125": _temp238,
  "SSS30-126": _temp239,
  "SSS30-127": _temp240,
  "SSS30-128": _temp241,
  "SSS30-129": _temp242,
  "SSS30-13": _temp243,
  "SSS30-130": _temp244,
  "SSS30-131": _temp245,
  "SSS30-133": _temp246,
  "SSS30-134": _temp247,
  "SSS30-135": _temp248,
  "SSS30-136": _temp249,
  "SSS30-137": _temp250,
  "SSS30-138": _temp251,
  "SSS30-139": _temp252,
  "SSS30-14": _temp253,
  "SSS30-140": _temp254,
  "SSS30-141": _temp255,
  "SSS30-142": _temp256,
  "SSS30-143": _temp257,
  "SSS30-144": _temp258,
  "SSS30-145": _temp259,
  "SSS30-146": _temp260,
  "SSS30-147": _temp261,
  "SSS30-148": _temp262,
  "SSS30-149": _temp263,
  "SSS30-15": _temp264,
  "SSS30-150": _temp265,
  "SSS30-151": _temp266,
  "SSS30-152": _temp267,
  "SSS30-153": _temp268,
  "SSS30-154": _temp269,
  "SSS30-155": _temp270,
  "SSS30-16": _temp271,
  "SSS30-17": _temp272,
  "SSS30-18": _temp273,
  "SSS30-19": _temp274,
  "SSS30-20": _temp275,
  "SSS30-21": _temp276,
  "SSS30-22": _temp277,
  "SSS30-23": _temp278,
  "SSS30-24": _temp279,
  "SSS30-25": _temp280,
  "SSS30-26": _temp281,
  "SSS30-27": _temp282,
  "SSS30-28": _temp283,
  "SSS30-29": _temp284,
  "SSS30-30": _temp285,
  "SSS30-31": _temp286,
  "SSS30-32": _temp287,
  "SSS30-33": _temp288,
  "SSS30-33A": _temp289,
  "SSS30-34": _temp290,
  "SSS30-36": _temp291,
  "SSS30-38": _temp292,
  "SSS30-40": _temp293,
  "SSS30-41": _temp294,
  "SSS30-42": _temp295,
  "SSS30-43": _temp296,
  "SSS30-44": _temp297,
  "SSS30-45": _temp298,
  "SSS30-46": _temp299,
  "SSS30-47": _temp300,
  "SSS30-48": _temp301,
  "SSS30-49": _temp302,
  "SSS30-50": _temp303,
  "SSS30-50M": _temp304,
  "SSS30-51": _temp305,
  "SSS30-52": _temp306,
  "SSS30-53": _temp307,
  "SSS30-54": _temp308,
  "SSS30-55": _temp309,
  "SSS30-56": _temp310,
  "SSS30-57": _temp311,
  "SSS30-61": _temp312,
  "SSS30-80": _temp313,
  "SSS30-81": _temp314,
  "SSS30-82": _temp315,
  "SSS30-83": _temp316,
  "SSS30-87": _temp317,
  "SSS30-87SL": _temp318,
  "SSS30-88": _temp319,
  "SSS30-90": _temp320,
  "SSS30-91": _temp321,
  "SSS30-92": _temp322,
  "SSS30-93": _temp323,
  "SSS30-94": _temp324,
  "SSS30-95": _temp325,
  "SSS30-96": _temp326,
  "SSS30-97": _temp327,
  "SSS31-39F": _temp328,
  "SSS31-39G": _temp329,
  "SSS31-39W": _temp330,
  "SSS31-39Y": _temp331,
  "SSS31-39Р": _temp332,
  "SSS5-02": _temp333,
  "SSS5-06": _temp334
}