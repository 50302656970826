const _temp0 = require("url:./drop.mp3");
const _temp1 = require("url:./posypki-blestki.mp3");
const _temp2 = require("url:./ps_1.mp3");
const _temp3 = require("url:./ps_10.mp3");
const _temp4 = require("url:./ps_11.mp3");
const _temp5 = require("url:./ps_12.mp3");
const _temp6 = require("url:./ps_2.mp3");
const _temp7 = require("url:./ps_3.mp3");
const _temp8 = require("url:./ps_4.mp3");
const _temp9 = require("url:./ps_5.mp3");
const _temp10 = require("url:./ps_6.mp3");
const _temp11 = require("url:./ps_7.mp3");
const _temp12 = require("url:./ps_8.mp3");
const _temp13 = require("url:./ps_9.mp3");
const _temp14 = require("url:./sharm_1.mp3");
const _temp15 = require("url:./sharm_2.mp3");
const _temp16 = require("url:./sharm_3.mp3");
const _temp17 = require("url:./sharm_4.mp3");
const _temp18 = require("url:./sharm_5.mp3");
const _temp19 = require("url:./sharm_6.mp3");
const _temp20 = require("url:./sharm_7.mp3");
const _temp21 = require("url:./sharm_8.mp3");
const _temp22 = require("url:./slide-prev.mp3");
const _temp23 = require("url:./slide.mp3");
const _temp24 = require("url:./slime.mp3");
const _temp25 = require("url:./squish.mp3");
module.exports = {
  "drop": _temp0,
  "posypki-blestki": _temp1,
  "ps_1": _temp2,
  "ps_10": _temp3,
  "ps_11": _temp4,
  "ps_12": _temp5,
  "ps_2": _temp6,
  "ps_3": _temp7,
  "ps_4": _temp8,
  "ps_5": _temp9,
  "ps_6": _temp10,
  "ps_7": _temp11,
  "ps_8": _temp12,
  "ps_9": _temp13,
  "sharm_1": _temp14,
  "sharm_2": _temp15,
  "sharm_3": _temp16,
  "sharm_4": _temp17,
  "sharm_5": _temp18,
  "sharm_6": _temp19,
  "sharm_7": _temp20,
  "sharm_8": _temp21,
  "slide-prev": _temp22,
  "slide": _temp23,
  "slime": _temp24,
  "squish": _temp25
}