const _temp0 = require("url:../SR002/SR002.png");
const _temp1 = require("url:../SR002/SR003.png");

const _temp2 = require("url:../SR003/SR002.png");
const _temp3 = require("url:../SR003/SR003.png");

const _temp4 = require("url:../SSS30-146/SR002.png");
const _temp5 = require("url:../SSS30-146/SR003.png");

module.exports = {
  "SR002":   {
    "SR002": _temp0,
    "SR003": _temp1
  },
  "SR003":   {
    "SR002": _temp2,
    "SR003": _temp3
  },
  "SSS30-146":   {
    "SR002": _temp4,
    "SR003": _temp5
  }
}