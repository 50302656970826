import anime from "animejs"
import {getCurrentRotationFixed} from "./helper"
function initAnimation() {
    anime({
        targets: '.section-1 .star',
        translateY: [
          {
            value: '-=20px'
          },
          {
            value: '+=20px'
          },
        ],
        translateX: {
          value: '-=0px'
        },
        rotate: [
          {
           value: function(el){return getCurrentRotationFixed(el) + 10},
           transformOrigin: 'center bottom'
          },
          {
            value: function(el){return getCurrentRotationFixed(el) - 10},
            transformOrigin: 'center bottom'
          },
          {
            value: function(el){return getCurrentRotationFixed(el)},
            transformOrigin: 'center bottom'
          },
    
        ],
          
        duration: 4000,
        loop: true,
        
        delay: anime.stagger(100),
        easing: 'easeInOutCubic'
    });

    anime({
      targets: '.labstypes .labstype img',
      translateY: [
        {
          value: '-=20px'
        },
        {
          value: '+=20px'
        },
      ],
      duration: 2000,
      loop: true,
      delay: anime.stagger(200, {from: 'center'}),
      easing: 'easeInOutCubic'
    });
}
export {initAnimation}