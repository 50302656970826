
function initCarousels() {
    $('#sync1').slick({
        // margin: 10,
        arrow: true,
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        rows: 0,
        asNavFor: '#sync2',
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    })
    $('#sync2').slick({
        arrow: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        asNavFor: '#sync1',
        
    })
    $('.slick.standard').slick({
        arrow: true,
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        rows: 0,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    const labstypes_config = {
        arrow: false,
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToShow: 8,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: null,
        nextArrow: null,
        rows: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 6,
                    autoplay: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    autoplay: true,
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 4,
                    autoplay: true,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    autoplay: true,
                }
            },
            {
                breakpoint: 555,
                settings: {
                    slidesToShow: 2,
                    autoplay: true,
                }
            },
            {
                breakpoint: 402,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                }
            }
        ]
    }
    const $labstypes = $('.slick.labstypes-slick')
    

    $labstypes.slick(labstypes_config);
   
    
    $('#sound-checkbox').on('change', function(e){
        if(e.target.checked) {
            $('#sync2 .slick-slide video').each(function(i, el){
                el.muted = false
            })
        }else{
            $('#sync2 .slick-slide video').each(function(i, el){
                el.muted = true
            })
        }
    })
    $('#sync2').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        
        let old_vid = $(`#sync2 .slick-slide:eq(${currentSlide+1})`).find('video').get(0);
        old_vid.pause();

        let cur_vid = $(`#sync2 .slick-slide:eq(${nextSlide+1})`).find('video').get(0);
        cur_vid.currentTime = 0;
        cur_vid.play();
    
    });

    $('.slick.choose-slime').on('init', function(){
        $(`.slick.choose-slime .slick-slide.slick-current`).next().addClass('next')
        $(`.slick.choose-slime .slick-slide.slick-current`).prev().addClass('prev')
    });
    $('.slick.choose-slime').slick({
        arrow: true,
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        speed: 500,
        rows: 0,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    $('.slick.additional-products').slick({
        arrow: true,
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        rows: 0,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });
    $('.slick.mixed-items').slick({
        arrow: true,
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        rows: 0,
        // autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
    });
    $('.slick.step-carousel').slick({
        arrow: true,
        infinite: true,
        // centerMode: true,
        centerPadding: 0,
        // autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        rows: 0,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        responsive: [
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 3
                }
            },
        ]
    });
}
export {initCarousels}