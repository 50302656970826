import "./import-jquery"
import "./jquery-ui.min"
import 'bootstrap/js/dist/modal';
import "slick-carousel"
import {initCarousels} from "./carousels"
import {initAnimation} from "./animations"
import {initGame} from "./game"
import {initShop} from "./shop"
import {initForms} from "./forms"
import {initVideos} from "./videos"

$(function () {
    initCarousels()
    initAnimation()
    initGame()
    initShop()
    initForms()
    initVideos()
    $('.mixed-item .buy-btn, .mixed-item .close-links').on('click', function(){
        $(this).closest('.mixed-item__info').toggleClass('links-opened')
    })
    
})
