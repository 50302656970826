import droped from 'url:../img/game/pricsipka_dropped/*.png'
import sounds from 'url:../audio/*.mp3'
import products from 'url:../img/game/products/*.png'
import popup_photos from 'url:../img/game/popup-photo/**/*.png'

function initGame() {
    // $(document).on('click', '.mixed-item__img', showProductImages)
    // $(document).on('click', '.images-modal .close-modal', () => {
    //     $('body').removeClass('images-modal-opened')
    //     $('.images-modal__content').remove()
    // })
    function showProductImages() {
        console.log(popup_photos)
        let images = Object.values(popup_photos[$(this).closest('.mixed-item').data('sku')])
        console.log(images)
        $('body').addClass('images-modal-opened')
        let data = '<div class="images-modal__content">';
        images.forEach((image) => {
            data += `<img src="${image}">`;
        })
        data += `</div>`;
        $('.images-modal').append(data);
        popup_slick = $('.images-modal__content').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            arrows: true,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
        })
        
    }
    const slide_audio = new Audio(sounds.slide);
    const drop_audio = new Audio(sounds.squish);
    function takeItemAniation(event, ui){
        $(event.target).addClass('taken')
    }
    function dropItemAniation(event, ui){
        $(event.target).removeClass('taken')
    }

    $( "#step-2 .draggable-item" ).draggable({
        zIndex: 100,
        containment: "#step-2 .step-grid",
        start: takeItemAniation,
        stop: dropItemAniation
    });
    $( "#step-3 .draggable-item" ).draggable({
        zIndex: 100,
        containment: "#step-3 .step-grid",
        start: takeItemAniation,
        stop: dropItemAniation
    });

    function dropItemSound(event, ui, slick = false){
        if (!slick){
            let data_sound = $(ui.draggable).data('sound')
            let sound = data_sound ? new Audio(sounds[data_sound]) : drop_audio
            sound.play()
        }else{
            let data_sound = $(event.target).data('sound')
            let sound = data_sound ? new Audio(sounds[data_sound]) : drop_audio
            sound.play()
        }
    }
    function dropItem(event, ui){
        if (!$(ui.draggable).hasClass('draggable-item--2')){
            let ps = ui.draggable.data('ps');
            ui.draggable.css({'visibility': 'hidden'});
            $(event.target).append(`<img src="${game.ps[ps]}">`)
            game.selected_ps.push(ps);
        }else{
            let src = $(ui.draggable).attr('src');
            $('.draggable-item--2').css({'top': 0, 'left': 0}).css({'visibility': 'visible'});
            ui.draggable.css({'visibility': 'hidden'});
            $(event.target).children('.choosen-charm').remove();
            $(event.target).append(`<img class="choosen-charm" src="${src}">`)
            game.selected_sharm = $(ui.draggable).data('sharm');
            game.selected_sharm_sku = $(ui.draggable).data('sharm_sku');
            game.selected_mix = $(ui.draggable).data('mix');
            
        }
    }
    $( ".droppable-item" ).droppable({
      drop: function( event, ui ) {
        dropItemSound(event,ui)
        dropItem(event, ui)
      }
    });

    $('.step-next').on('click', function(e) {
        e.preventDefault();
        if (game.selected_sharm != ''){
            game.next(game.selected_sharm == 'right' ? 2 : 1);
            game.selected_sharm == 'right' ? game.setSharm() : game.setMix();
        }else if (game.selected_sharm == '' && $(this).closest('.step').attr('id') == 'step-3'){
            alert('Выберите шарм'); // TODO 
        }else{
            game.next();
        }
        
    })
    $('.step-back').on('click', function(e) {
        e.preventDefault()
        game.prev();
        
    })
    $('.reset').on('click', function(e) {
        e.preventDefault()
        game.reset();
        
    })
    $('.slick.choose-slime').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.slick.choose-slime .slick-slide').removeClass('next prev')
        $(`.slick.choose-slime .slick-slide[data-slick-index=${nextSlide}]`).next().addClass('next')
        $(`.slick.choose-slime .slick-slide[data-slick-index=${nextSlide}]`).prev().addClass('prev')
        sound.play()
    });
    const sound = new Audio(sounds.slime);
    $('.slick.choose-slime').on('afterChange', function(slick, currentSlide){
        
       
    });
    
    $('#step-1 .step-next').on('click', function(e) {
        game.product_color = $('.slick-center.choose-slime__item').data('color');
        game.color = $('.slick-center.choose-slime__item img').attr('src');
        
    })
    $(document).on('click', '.slick.step-carousel .item', function(event) {
        if ($(this).data('ps') && game.selected_ps.indexOf($(this).data('ps'))  == -1){
            dropItemSound(event, 0, true)
            let ps = $(this).data('ps');
            $(this).closest('.step').find('.droppable-item').append(`<img src="${game.ps[ps]}">`)
            game.selected_ps.push(ps);
        }
        if ($(this).data('sharm')){
            dropItemSound(event, 0, true)
            $(this).closest('.step').find('.droppable-item .choosen-charm').remove();
            $(this).closest('.step').find('.droppable-item').append(`<img class="choosen-charm" src="${$(this).attr('src')}">`)
            game.selected_sharm = $(this).data('sharm');
            game.selected_sharm_sku = $(this).data('sharm_sku');
            game.selected_mix = $(this).data('mix');
        }
        
    })
    
    const game = {
        _step: 0,
        _color: 'lilac',
        product_color: 'pink',
        // colors: slime_colors_imgs,
        selected_ps: [],
        selected_sharm: '',
        selected_sharm_sku: '',
        selected_mix: '',
        sharms: [
            {
                sku: 'SSS30-150',
                title: 'Набор шармов «Свежий хлеб»',
                wb_link: 'https://www.wildberries.ru/catalog/15066602/detail.aspx?targetUrl=BP'
            },
            {
                sku: 'SSS30-145',
                title: 'Набор шармов «‎Призраки»',
                wb_link: 'https://www.wildberries.ru/catalog/15066597/detail.aspx?targetUrl=BP'
            },
            {
                sku: 'SSS30-146',
                title: 'Набор шармов «‎Русалочка»',
                wb_link: 'https://www.wildberries.ru/catalog/15066598/detail.aspx?targetUrl=BP'
            },
            {
                sku: 'SSS30-153',
                title: 'Набор шармов «‎Милый альпака»',
                wb_link: 'https://www.wildberries.ru/catalog/15066605/detail.aspx?targetUrl=BP'
            },
            
        ],
        ps: droped,
        // products: ['SR002', 'SR003', 'SR004'],
        vedra: [
            {
                sku: 'SR002',
                title: '«Единорог»',
                wb_link: 'https://www.wildberries.ru/catalog/18601349/detail.aspx?targetUrl=BP'
            },
            {
                sku: 'SR003',
                title: '«Морской»',
                wb_link: 'https://www.wildberries.ru/catalog/18601350/detail.aspx?targetUrl=BP'
            },
            {
                sku: 'SR004',
                title: '«Фрукты»',
                wb_link: 'https://www.wildberries.ru/catalog/18601351/detail.aspx?targetUrl=BP'
            },
        ],
        slimers_mix: [
            {
                sku: 'SR149',
                title: '«Sweet Candy»',
                wb_link: '#'
            },
            {
                sku: 'SR150',
                title: '«Lovely Unicorn»',
                wb_link: '#'
            },
            {
                sku: 'SR151',
                title: '«Funny Avocado»',
                wb_link: '#'
            },
            {
                sku: 'SR152',
                title: '«Magic Ocean»',
                wb_link: '#'
            },
        ],
        set color(a) {
            this._color = a;
            $('#step-2 .step-grid__slime>img, #step-3 .step-grid__slime>img').attr('src', a);
            this.setVedro();
        },
        setMix: function () {
            let slimer_mix = this.slimers_mix.find(el => el.sku == this.selected_mix)
            $('.mixed-item.sm-mix').attr('data-sku', slimer_mix.sku);
            $('.mixed-item.sm-mix .mixed-item__img img').attr('src', products[slimer_mix.sku])
            $('.mixed-item.sm-mix .mixed-item__title').text(slimer_mix.title)
            // $('.mixed-item.sm-mix .wb-link').attr('href', slimer_mix.wb_link)
        },
        setVedro : function (){
            let vedro;
            switch (this.product_color) {
                case 'pink':
                    vedro = this.vedra.find(el => el.sku == 'SR002');
                    break;
                case 'turquoise':
                    vedro = this.vedra.find(el => el.sku == 'SR003');
                    break;
                case 'green':
                    vedro = this.vedra.find(el => el.sku == 'SR004');
                    break;
                default:
                    vedro = this.vedra[Math.floor(Math.random()*this.vedra.length)];
            }
            $('.mixed-item.vedro').attr('data-sku', vedro.sku);
            $('.mixed-item.vedro .mixed-item__img img').attr('src', products[vedro.sku]);
            $('.mixed-item.vedro .mixed-item__title').text(vedro.title);
            $('.mixed-item.vedro .wb-link').attr('href', vedro.wb_link);
        },
        setSharm: function () {
            let sharm = this.sharms.find(el => el.sku == this.selected_sharm_sku)
            $('.mixed-item.sharm').attr('data-sku', sharm.sku);
            $('.mixed-item.sharm .mixed-item__img img').attr('src', products[sharm.sku])
            $('.mixed-item.sharm .mixed-item__title').text(sharm.title)
            $('.mixed-item.sharm .wb-link').attr('href', sharm.wb_link)
        },
        next: function (size = 1){
            slide_audio.play()
            if (this._step == 2) {
                
                this.selected_ps.forEach((val) =>{
                    $('#step-3 .droppable-item').append(`<img class="choosen-ps" src="${this.ps[val]}">`)
                })
            }
            this._step += size;
            $('.step').css({'transform': `translateX(-${this._step * 100}%)`})
            console.log(this._step)
        },
        prev: function (){
            slide_audio.play()
            this._step--
            this.selected_sharm = ''
            $('.step').css({'transform': `translateX(-${this._step * 100 }%)`})
            if (this._step < 3 ){
                console.log('Remove')
                $('#step-2 .droppable-item>img').remove(); // Hmmm
                $('#step-3 .droppable-item>img').remove();
                this.selected_ps = [] //Hmmmm
                $('.draggable-item').css({'top': 0, 'left': 0}).css({'visibility': 'visible'});
            }
            
        },
        reset: function (){
            this._step = 0
            $('.step').css({'transform': `translateX(-${this._step * 100 }%)`})
            $('#step-2 .droppable-item>img').remove(); // Hmmm
            $('#step-3 .droppable-item>img').remove();
            $('.draggable-item').css({'top': 0, 'left': 0}).css({'visibility': 'visible'});
            this.selected_ps = []
            this.selected_sharm = ''
        }
        
    }
}
export {initGame}