import videos from 'url:../video/mobile/*.mp4'

function initVideos(){
    let video_state = 'desktop'
    if ($(window).width() < 376) {
        changeVideos()
        video_state = 'mobile'
    }
    $(window).on('resize', function () {
        if ($(window).width() < 376 && video_state == 'desktop') {
            changeVideos()
            video_state = 'mobile'
        }else if ($(window).width() > 375 && video_state == 'mobile') {
            changeVideos()
            video_state = 'desktop'
        }
    })
    function changeVideos() {
        if (video_state == 'desktop') {
            let keys = Object.keys(videos);
            $('#sync2 .video video').each(function(i, el) {
                let desktop_video = $(el).attr('src')
                $(el).data('desktop_video', desktop_video);
            })
            keys.forEach(function(el){
                $(`.video.${el} video`).attr('src', videos[el]);
            });
            $('#sync2 .video video').each(function(i, el) {
                $(el)[0].autoplay = false;
                
            })
            $('#sync2 .slick-current video').get(0).play()
        }else{
            $('#sync2 .video video').each(function(i, el) {
                let desktop_video = $(el).data('desktop_video')
                $(el).attr('src', desktop_video);
            })
            $('#sync2 .video video').each(function(i, el) {
                $(el)[0].autoplay = false;
                
            })
            $('#sync2 .slick-current video').get(0).play()
        }
        
    }
}

export {initVideos}